<!--
 * @Author: 刘格优
 * @Date: 2020-03-21 09:19:09
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-07 16:14:03
 -->
<template>
  <div></div>
</template>

<script>
import { showEmpInfo } from '@/api/agent/agent'
import config from '@/config'
export default {
  data () {
    return {
      checkRytype: '',
      gotype: 'renzheng',
      applynum: ''
    };
  },
  beforeRouteEnter (to, from, next) {
    next(that => {
      // from.path == '/login' ? that.isShow = true : ''
      // console.log(from.path)
      if (from.path == '/') {
        // this.$router.go(-2)
        showEmpInfo().then((res) => {
          that.checkRytype = res.data.data.salesmanInfo.checkRytype
          that.applynum = res.data.data.salesmanInfo.applynum
          if (that.checkRytype == '3' || that.checkRytype == '4') {
            that.$router.push('/professional')
          } else if (that.checkRytype == '7') {
            that.$router.push(`/fundamentals?applynum=${that.applynum}`)
          } else {
            that.$router.push('/dzincome')
          }
        })
      } else {
        location.replace('/')
      }
    })
  },

  // created () {

  // },
  methods: {}
}

</script>
<style scoped>
</style>